@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  margin: 0px;
  background-color: rgb(0, 0, 0);
  font-family: 'Roboto', sans-serif;
  color: white;
  /* scroll-behavior: smooth; */
}

@font-face {
  font-family: 'cyberdyne';
  src: url('./fonts/cyberdyne.ttf') format('truetype');
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

main {
  width: 100vw;
  height: 200vw;
  z-index: 99;
  position: absolute;
  justify-content: center;
  text-align: center;
  pointer-events: none;
  font-size: 12px;
  margin-top: 30px;
}

h1 span {
  display: block;
  font-size: 1em;
  line-height: 1.3;
}

h1 {
  font-family: "cyberdyne", sans-serif;
}

h1 em {
  font-style: normal;
  font-weight: 600;
}


.eight h1 {
  text-align: center;

  text-transform: uppercase;
  font-size: 90px;
  letter-spacing: 1px;
  margin-top: 430px;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 16px 0;
  grid-gap: 22px;
}

.eight h1:after,
.eight h1:before {
  content: " ";
  display: block;
}

.progress-bar-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#progress-bar {
  width: 30%;
  margin-top: .5%;
  height: 2%;
}

label {
  color: white;
}

.container {
  max-width: 1180px;
  width: 95%;
  margin: 0 auto;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
}

header h2 {
  font-weight: 400;
}

.scroll-container {
  height: 200vh;
  min-height: 350px;
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #222;
  animation: fadeIn 5s;
  animation: fadeOut 5s;
}

@keyframes scroll-container {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.scroll-container:nth-of-type(even) {
  flex-direction: row-reverse;
}

.scroll-element,
.scroll-caption {
  width: 50%;
}

.scroll-element {
  min-height: 300px;
  height: 100%;
  background-color: #eaeaea;
}

.scroll-caption {
  margin: 1rem;
}

@media screen and (max-width: 650px) {

  .scroll-container,
  .scroll-container:nth-of-type(even) {
    flex-direction: column;
    align-content: inherit;
  }

  .scroll-element {
    height: 100%;
  }

  .scroll-element,
  .scroll-caption {
    width: 100%;
  }
}

/**Styling scrollable elements*/

.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.fade-in {
  animation: fade-in 1s ease-in-out both;
}

.scrolled.fade-in-bottom {
  animation: fade-in-bottom 1s ease-in-out both;
}

.scrolled.slide-left {
  animation: slide-in-left 1s ease-in-out both;
}

.scrolled.slide-right {
  animation: slide-in-right 1s ease-in-out both;
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.scroll-container section {
  margin-top: 7.5rem;
}

p {
  font-size: 1.125rem;
  line-height: 1.33333333333;
  color: #000000;
  margin-top: 1.25rem;
  max-width: 600px;
  /* padding-left: 1.75rem; */
  margin-left: auto;
  margin-right: auto;
}

.menu ul {
  display: flex;
  list-style: none;
}

.menu li+li {
  margin-left: 5rem;
}

.menu a {
  display: inline-block;
  font-size: 1.125rem;
  line-height: 1.33333333333;
  color: #fff;
  text-decoration: none;
  padding: 1.125rem 0;
}

.menu a:hover {
  color: #4dc8ff;
}

.content {
  padding-right: 1rem;
  padding-left: 1rem;
}

.hero-title {
  margin-top: 11.25rem;
}

.hero-title,
.title {
  font-size: 4rem;
  line-height: 1.125;
  color: #000000;
}

.hero-title span,
.title span {
  color: #4dc8ff;
}

.title {
  padding-left: 1.25rem;
  border-left: 0.5rem solid #4dc8ff;
}

.link {
  font-size: 1.125rem;
  display: inline-block;
  text-decoration: none;
  color: #4dc8ff;
  margin-top: 1rem;
  margin-left: 1.75rem;
}

.link:hover {
  text-decoration: underline;
}

small {
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #a1a1a6;
  margin-top: 80vh;
  margin-bottom: 5rem;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }

  .eight h1 {
    margin-top: 300px;
  }
}

@media (max-width: 450px) {
  .menu li+li {
    margin-left: 2rem;
  }
}